import React from 'react';
import { Card, CardContent, Typography, Container, Grid, Box } from '@mui/material';
import "../App.css"

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="mx" sx={{ mt: 4, mb: 4 }}>
      <Card sx={{ p: 3 }}>
        <CardContent>
          <Typography variant="h4" component="h1" gutterBottom>
            Privacy Policy
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" gutterBottom>
            Last Updated: September 2024
          </Typography>

          <Box sx={{ my: 4 }}>
            <Typography variant="h6" component="h2" gutterBottom>
              1. Introduction
            </Typography>
            <Typography variant="body1" paragraph>
              Welcome to EV Trade Hub. We value your privacy and are committed to protecting your personal information. This Privacy Policy outlines the types of data we collect, how we use it, and your rights regarding your personal information.
            </Typography>
          </Box>

          <Box sx={{ my: 4 }}>
            <Typography variant="h6" component="h2" gutterBottom>
              2. Information We Collect
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>Personal Information:</strong> Name, email address, phone number, billing information, and other identifiable data you provide when purchasing a product, signing up for newsletters, or contacting us.
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>Non-Personal Information:</strong> Browser type, device information, IP address, and other anonymous data to enhance your user experience.
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>Cookies:</strong> We use cookies to improve website functionality and analyze site usage. You can manage cookie preferences in your browser settings.
            </Typography>
          </Box>

          <Box sx={{ my: 4 }}>
            <Typography variant="h6" component="h2" gutterBottom>
              3. How We Use Your Information
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>To Process Orders:</strong> To handle your purchases and provide customer support.
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>To Communicate:</strong> We may contact you with updates, promotional offers, or respond to inquiries.
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>To Improve Our Services:</strong> We use analytics to understand customer preferences and improve our website and offerings.
            </Typography>
          </Box>

          <Box sx={{ my: 4 }}>
            <Typography variant="h6" component="h2" gutterBottom>
              4. Sharing Your Information
            </Typography>
            <Typography variant="body1" paragraph>
              We do not sell or rent your personal information. We may share your data with trusted third parties, such as payment processors, to complete transactions or comply with legal obligations.
            </Typography>
          </Box>

          <Box sx={{ my: 4 }}>
            <Typography variant="h6" component="h2" gutterBottom>
              5. Security
            </Typography>
            <Typography variant="body1" paragraph>
              We use industry-standard security measures to protect your data. However, no online service is entirely risk-free, and we encourage you to take precautions.
            </Typography>
          </Box>

          <Box sx={{ my: 4 }}>
            <Typography variant="h6" component="h2" gutterBottom>
              6. Your Rights
            </Typography>
            <Typography variant="body1" paragraph>
              You have the right to access, correct, or delete your personal information. To exercise these rights, contact us at opsmanager@esavari.in.
            </Typography>
          </Box>

          <Box sx={{ my: 4 }}>
            <Typography variant="h6" component="h2" gutterBottom>
              7. Changes to This Policy
            </Typography>
            <Typography variant="body1" paragraph>
              We may update this Privacy Policy periodically. We will notify you of significant changes by posting the new policy on our website.
            </Typography>
          </Box>

          <Box sx={{ my: 4 }}>
            <Typography variant="h6" component="h2" gutterBottom>
              8. Contact Us
            </Typography>
            <Typography variant="body1" paragraph>
              If you have any questions or concerns about our Privacy Policy, please contact us at:
            </Typography>
            <Typography variant="body1" paragraph>
              EV Trade Hub <br />
               <br />
               <b>
              Email: info@evtradehub.in <br />
              Phone: 9111777280
              </b>
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default PrivacyPolicy;
