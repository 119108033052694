import React from 'react';
import { Box, Grid, Typography, Link as MuiLink, Container, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { Link as RouterLink } from 'react-router-dom';
import "../App.css";

const Footer = () => {
  return (
    <Box sx={{ backgroundColor: '#333', color: 'white', py: 4 }}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom>
              <div className='mobile_footer'>
                <LocalPhoneIcon /> <a href="tel:+919111777280" style={{ color: 'inherit', textDecoration: 'none' }}>+91 9111777280</a>     
              </div>
            </Typography>
            <Typography variant="body2">
              18, Gulab Bagh in front of Metro <br/> Sale Dews Naka Indore M.P.
            </Typography>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom>
              Quick Links
            </Typography>
            <MuiLink component={RouterLink} to="/privacy" color="inherit" underline="hover" sx={{ display: 'block', mb: 1 }}>
              Privacy Policy
            </MuiLink>
            <MuiLink component={RouterLink} to="/term" color="inherit" underline="hover" sx={{ display: 'block', mb: 1 }}>
              Terms & Conditions
            </MuiLink>
            <MuiLink component={RouterLink} to="/contact" color="inherit" underline="hover" sx={{ display: 'block' }}>
              Contact Us
            </MuiLink>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom>
              Follow Us
            </Typography>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <IconButton href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" color="inherit">
                <FacebookIcon />
              </IconButton>
              <IconButton href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" color="inherit">
                <InstagramIcon />
              </IconButton>
              <IconButton href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" color="inherit">
                <TwitterIcon />
              </IconButton>
              <IconButton href="https://www.youtube.com" target="_blank" rel="noopener noreferrer" color="inherit">
                <YouTubeIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
