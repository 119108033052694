import React from 'react';
import { Container, Typography, Box, Grid, Card, CardContent, Divider } from '@mui/material';
import "../App.css"

const AboutUs = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom align="center">
        About Us
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom align="center">
        Welcome to EV Trade Hub
      </Typography>
      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Typography variant="body1" paragraph>
            At EV Trade Hub, we are driven by the vision of revolutionizing the B2B electric scooter market with cutting-edge technology and exceptional customer service. Our mission is to empower businesses by providing them with top-of-the-line electric scooters and parts that combine performance, sustainability, and affordability.
          </Typography>
          <Typography variant="h6" component="h3" gutterBottom>
            Our Story
          </Typography>
          <Typography variant="body1" paragraph>
            Founded in 2019, EV Trade Hub started with a simple idea: to make electric mobility accessible and reliable for businesses of all sizes. We recognized the growing demand for eco-friendly transportation and set out to develop solutions that meet the unique needs of the B2B market.
          </Typography>
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6" component="h3" gutterBottom>
            Why Choose Us?
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Card>
                <CardContent>
                  <Typography variant="body1" paragraph>
                    <strong>Innovative Products:</strong> Our electric scooters are designed with the latest technology to ensure efficiency, durability, and style. Whether you’re looking for a fleet of scooters for delivery services or specialized parts for your existing vehicles, we’ve got you covered.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card>
                <CardContent>
                  <Typography variant="body1" paragraph>
                    <strong>Sustainability:</strong> We believe in a greener future. Our products are not only designed to reduce carbon footprints but are also manufactured using environmentally friendly processes.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card>
                <CardContent>
                  <Typography variant="body1" paragraph>
                    <strong>Customer-Centric Approach:</strong> Your satisfaction is our priority. We offer personalized solutions, flexible purchasing options, and dedicated support to help you get the most out of our products.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card>
                <CardContent>
                  <Typography variant="body1" paragraph>
                    <strong>Global Reach, Local Touch:</strong> With a presence in multiple countries, we serve businesses worldwide while maintaining a strong commitment to local communities.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6" component="h3" gutterBottom>
            Our Vision for the Future
          </Typography>
          <Typography variant="body1" paragraph>
            As we continue to grow, our focus remains on innovation and sustainability. We aim to lead the industry in electric mobility solutions, setting new standards for quality and customer satisfaction.
          </Typography>
          <Typography variant="body1" paragraph>
            Join us on our journey to a cleaner, more efficient world. Together, we can drive the future of business mobility.
          </Typography>
        </CardContent>
      </Card>
      <Box sx={{  mt: 4 }}>
        <Typography variant="h6" component="h4" gutterBottom>
          Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          We’d love to hear from you. Whether you have a question, need assistance, or want to learn more about our products, our team is here to help.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>EV Trade Hub</strong><br />
          <br />
          Email: info@evtradehub.in<br />
          Phone:+91 9111777280
        </Typography>
      </Box>
    </Container>
  );
};

export default AboutUs;
