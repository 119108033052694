import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import "../App.css"

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: 'black' }}>
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
            <img className="logo_IMG" src="../Product_IMG/EVTradeHUB.png" width={"30%"} alt='ev logo'/>
          </Link>
        </Typography>

        {/* Desktop View */}
        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          <Button
            sx={{
              color: 'white',
              margin: '0 10px',
              borderRadius: '8px',
              '&:hover': {
                backgroundColor: 'white',
                color: '#010438',
              },
            }}
            component={Link}
            to="/"
          >
            HOME
          </Button>
          <Button
            sx={{
              color: 'white',
              margin: '0 10px',
              borderRadius: '8px',
              '&:hover': {
                backgroundColor: 'white',
                color: '#010438',
              },
            }}
            component={Link}
            to="/about"
          >
            About
          </Button>
          <Button
            sx={{
              color: 'white',
              margin: '0 10px',
              borderRadius: '8px',
              '&:hover': {
                backgroundColor: 'white',
                color: '#010438',
              },
            }}
            component={Link}
            to="/contact"
          >
            Contact
          </Button>
          <Button
            sx={{
              color: 'white',
              margin: '0 10px',
              borderRadius: '8px',
              '&:hover': {
                backgroundColor: 'white',
                color: '#010438',
              },
            }}
            component={Link}
            to="/productnew"
          >
            Product
          </Button>
        </Box>

        {/* Mobile View */}
        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleMenuOpen}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleMenuClose} component={Link} to="/">
              HOME
            </MenuItem>
            <MenuItem onClick={handleMenuClose} component={Link} to="/about">
              About
            </MenuItem>
            <MenuItem onClick={handleMenuClose} component={Link} to="/contact">
              Contact
            </MenuItem>
            <MenuItem onClick={handleMenuClose} component={Link} to="/privacy">
              Privacy Policy
            </MenuItem>
            <MenuItem onClick={handleMenuClose} component={Link} to="/term">
              Term Condition
            </MenuItem>
            <MenuItem onClick={handleMenuClose} component={Link} to="/product:1">
              Product
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
