import React from 'react';
import { Box, Grid, Card, CardContent, Typography, Avatar } from '@mui/material';

const testimonials = [
  {
    name: 'Ranjana Singh',
    message: 'I was hesitant about ordering an electric scooter from overseas, but this company exceeded my expectations! The customer service was top-notch, guiding me through the process from start to finish. My scooter arrived on time, and the quality is outstanding. I’ve already recommended it to my friends and will definitely be ordering again!',
    img: 'https://media.istockphoto.com/id/1331576522/photo/happy-young-girl-sitting-over-motor-scooter.jpg?s=612x612&w=0&k=20&c=eWOZUzEC17_BoJptn6TjOGGQpfHZLruChm4nmSRDAEg=',
  },
  {
    name: 'Ankit Rana',
    message: 'I run a scooter rental business in Australia, and importing scooters from this company has been a game-changer for us. The scooters are durable, easy to maintain, and our customers love them. The delivery was smooth, and their global shipping options made the process seamless. Highly recommended for any business looking for reliable electric scooters.',
    img: 'https://st.depositphotos.com/1771835/2740/i/950/depositphotos_27403227-stock-photo-attractive-young-man-thumbs-up.jpg',
  },
  {
    name: 'Ankita Shrivastav',
    message: 'As a distributor in Europe, finding a reliable supplier for electric scooters has been a challenge. But this company has been fantastic! The quality of their products is unmatched, and their shipping is quick and efficient. My clients are extremely happy with the performance and sleek design of these scooters. I could not be more satisfied with the partnership.',
    img: 'https://media.gettyimages.com/id/1666610070/photo/portrait-of-happy-young-couple-on-scooter-enjoying-road-trip.jpg?s=612x612&w=0&k=20&c=-DhclmTCfXuRy5Lm8MqjCQ4jlZ8Agw_ZtInRAX6pvLQ=',
  },
];

const TestimonialCarousel = () => {
  return (
    <Box sx={{ py: 5, px: 3, backgroundColor: '#f4f4f4' }}>
      <Typography variant="h4" align="center" gutterBottom>
        What Our Customers Say
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {testimonials.map((testimonial, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={index}
            sx={{
              marginBottom: { xs: 2, sm: 0 }, // Adds margin bottom for mobile view (xs)
            }}
          >
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                p: 2,
                boxShadow: 3, // Box shadow
                transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out', // Smooth transition
                '&:hover': {
                  transform: 'scale(1.05)', // Slight zoom-in effect on hover
                  boxShadow: 6, // Increase box shadow on hover
                },
              }}
            >
              <Avatar
                alt={testimonial.name}
                src={testimonial.img}
                sx={{ width: 80, height: 80, mb: 2 }}
              />
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {testimonial.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {testimonial.message}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default TestimonialCarousel;
