import React, { useState, useEffect } from 'react';
import { TableContainer, Container, Grid, Card, CardMedia, Typography, Table, TableBody, TableCell, TableRow, Paper, TextField, Button, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress } from '@mui/material';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const ProductDetail = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [form, setForm] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    query: '',
  });
  const [sku, setSku] = useState(null);
  const [currentSkuIndex, setCurrentSkuIndex] = useState(0);
  const [currentImage, setCurrentImage] = useState(null); // State for the large image
  const [submissionStatus, setSubmissionStatus] = useState('');
  const [openPopup, setOpenPopup] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [phoneError, setPhoneError] = useState('');
  const [loading, setLoading] = useState(false); // Add loading state

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`https://twowheelerb2bapis.esavarirental.in/public/api/getProducts/${id}`);
        setProduct(response.data.data);
        if (response.data.data.skus && response.data.data.skus[0]?.id) {
          setSku(response.data.data.skus[0].id); // Set default SKU
          setCurrentImage(response.data.data.skus[0]?.image[0]?.source); // Set default large image
        }
      } catch (error) {
        console.error('Error fetching the product data:', error);
      }
    };

    fetchProduct();
  }, [id]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm({
      ...form,
      [name]: value,
    });

    // Clear phone error when user starts typing
    if (name === 'phone') {
      setPhoneError('');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate phone number
    const phoneRegex = /^[0-9]{10}$/;
    if (!phoneRegex.test(form.phone)) {
      setPhoneError('Mobile number must be exactly 10 digits');
      return;
    }

    setLoading(true); // Set loading to true when submission starts

    try {
      await axios.post('https://esavarirental.in/twowheelerb2bapis/public/api/submitQuery', {
        ...form,
        productId: id,
        skuId: sku,
      });
      setSubmissionStatus('Your query has been submitted successfully!');
      setOpenPopup(true);
      setIsSubmitted(true);
      setForm({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        query: '',
      });
    } catch (error) {
      console.error('Error submitting the query:', error);
      setSubmissionStatus('Failed to submit your query. Please try again.');
    } finally {
      setLoading(false); // Set loading to false after submission completes
    }
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const handleSkuChange = (index) => {
    setCurrentSkuIndex(index);
    setSku(product.skus[index]?.id);
    setCurrentImage(product.skus[index]?.image[0]?.source); // Set default large image for the selected SKU
  };

  const handleImageClick = (image) => {
    setCurrentImage(image); // Update large image when a thumbnail is clicked
  };

  if (!product) return <div>Loading...</div>;

  return (
    <>
      <Container sx={{ mt: 4 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Card>
              <CardMedia
                component="img"
                image={currentImage || '/placeholder.png'} // Display the selected large image
                alt={product.model_name}
                sx={{ height: 400, objectFit: 'contain' }}
              />
            </Card>

            <Box sx={{ mt: 1, display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
              {product.skus[currentSkuIndex]?.image.slice(1).map((img, index) => (
                <Box
                  key={index}
                  sx={{
                    width: { xs: 60, sm: 80, md: 100 },
                    height: { xs: 60, sm: 80, md: 100 },
                    m: 1,
                    cursor: 'pointer',
                    border: '1px solid #ccc',
                    borderRadius: 1,
                  }}
                  onClick={() => handleImageClick(img.source)} // Set the clicked image as the large image
                >
                  <CardMedia
                    component="img"
                    image={img.source ? img.source : '/placeholder.png'}
                    alt={`Thumbnail ${index}`}
                    sx={{ width: '100%', height: '100%', objectFit: 'contain' }}
                  />
                </Box>
              ))}
            </Box>

            <TableContainer component={Paper} sx={{ mt: 2, height: 480, overflow: 'hidden' }}>
            <Table sx={{ height: '100%', display: 'block' }}>
              <TableBody
                sx={{
                  display: 'block',
                  maxHeight: '480px',
                  overflowY: 'auto',
                  '&::-webkit-scrollbar': { width: 0, height: 0 },
                  '-ms-overflow-style': 'none',
                  'scrollbar-width': 'none',
                }}
              >
                {Object.entries(product).map(([key, value]) => {
                  if (key !== 'skus' && typeof value === 'string' && value) {
                    return (
                      <TableRow key={key} sx={{ display: 'table', width: '100%', tableLayout: 'fixed' }}>
                        <TableCell>{key.replace('_', ' ').toUpperCase()}</TableCell>
                        <TableCell>{value}</TableCell>
                      </TableRow>
                    );
                  }
                  return null;
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <br/>
          </Grid>

          {/* Product Info and SKU Selector */}
          <Grid item xs={12} md={6}>
            <Typography variant="h4" gutterBottom>
              {product.name}
            </Typography>
            <Typography variant="h6" gutterBottom>
              Model: {product.model_name} ({product.model_no})
            </Typography>

            <Box sx={{ display: 'flex', mt: 2, flexWrap: 'wrap' }}>
              {product.skus.map((sku, index) => (
                <Box
                  key={sku.id}
                  sx={{
                    width: { xs: 60, sm: 80, md: 100 },
                    height: { xs: 60, sm: 80, md: 100 },
                    m: 1,
                    cursor: 'pointer',
                    border: currentSkuIndex === index ? '2px solid #1976d2' : '1px solid #ccc',
                  }}
                  onClick={() => handleSkuChange(index)} // Change SKU
                >
                  <CardMedia
                    component="img"
                    image={sku.image[0]?.source ? sku.image[0]?.source : '/placeholder.png'}
                    alt={sku.name}
                    sx={{ width: '100%', height: '100%', objectFit: 'contain' }}
                  />
                  <Typography variant="caption" sx={{ textAlign: 'center', mt: 1 }}>
                    {sku.color}
                  </Typography>
                </Box>
              ))}
            </Box>
            <br/>
            <br/>
            <br/>

            {/* Other product details */}
            <Typography variant="h6" color="textSecondary" sx={{ mt: 2 }}>
              Model Number: {product.model_no}
            </Typography>
            <Typography variant="h6" color="textSecondary">
              Range: {product.range}
            </Typography>
            <Typography variant="h6" color="textSecondary">
              Max. Speed: {product.speed}
            </Typography>
            <Typography variant="h6" color="textSecondary">
              Charging Time: {product.charging_time}
            </Typography>
            <Typography variant="h6" color="textSecondary">
              Rated Power: {product.motor_power_rated}
            </Typography>

            {/* Request For Quotation Form */}
            <Box sx={{ mt: 4, p: 3, backgroundColor: 'white', borderRadius: 2 }}>
              <Typography variant="h6" gutterBottom>
                Request For Quotation
              </Typography>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="First Name"
                      name="first_name"
                      fullWidth
                      value={form.first_name}
                      onChange={handleChange}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Last Name"
                      name="last_name"
                      fullWidth
                      value={form.last_name}
                      onChange={handleChange}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Email"
                      name="email"
                      type="email"
                      fullWidth
                      value={form.email}
                      onChange={handleChange}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Phone"
                      name="phone"
                      type="number"
                      fullWidth
                      value={form.phone}
                      onChange={handleChange}
                      required
                      error={!!phoneError}
                      helperText={phoneError}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Query"
                      name="query"
                      fullWidth
                      multiline
                      rows={4}
                      value={form.query}
                      onChange={handleChange}
                      required
                    />
                  </Grid>
                </Grid>
                <Box sx={{ mt: 2 }}>
                  <Button variant="contained" type="submit" color="primary" disabled={loading}>
                    {loading ? <CircularProgress size={24} /> : 'Submit'}
                  </Button>
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Container>

      {/* Success or failure message popup */}
      <Dialog open={openPopup} onClose={handleClosePopup}>
        <DialogTitle>{submissionStatus}</DialogTitle>
        <DialogActions>
          <Button onClick={handleClosePopup} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProductDetail;
