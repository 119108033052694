import React from 'react';
import TestimonialCarousel from '../component/Testimonal';
import Herosection from '../component/Herosection';
import ContactForm from './Contact';
import ProductDisplay from '../component/Product';
import New_product from '../component/New_product';
import "../App.css";
<link rel="canonical" href="https://www.evtradehub/" />

const Home = () => {
  return (
    <div>
      <div className="hero-section">
        <h1 style={{ padding: "20px " }}>The Future of EV Two Wheelers </h1>
      </div>
      <Herosection />
      <New_product />
      <TestimonialCarousel />
      <ContactForm />
    </div>
  );
};

export default Home;
