import React from 'react';
import { Container, Typography, Box, Card, CardContent, Divider } from '@mui/material';
import "../App.css"
const TermsConditions = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom align="center">
        Terms and Conditions
      </Typography>
      <Card>
        <CardContent>
          <Typography variant="h6" component="h2" gutterBottom>
            1. Introduction
          </Typography>
          <Typography variant="body1" paragraph>
            Welcome to EV Trade Hub. These Terms and Conditions govern your use of our website and services. By accessing or using our site, you agree to comply with and be bound by these terms. If you do not agree, please do not use our website.
          </Typography>

          <Typography variant="h6" component="h2" gutterBottom>
            2. Use of Our Website
          </Typography>
          <Typography variant="body1" paragraph>
            You must be at least years old to use our website. You agree to use the site only for lawful purposes and in accordance with these terms. Any unauthorized use may result in termination of your access.
          </Typography>

          <Typography variant="h6" component="h2" gutterBottom>
            3. Product Information
          </Typography>
          <Typography variant="body1" paragraph>
            We strive to ensure that all information about our products is accurate and up-to-date. However, we do not warrant that product descriptions or other content on our website is accurate, complete, or current.
          </Typography>

          <Typography variant="h6" component="h2" gutterBottom>
            4. Orders and Payments
          </Typography>
          <Typography variant="body1" paragraph>
            All orders are subject to acceptance and availability. We reserve the right to cancel or refuse any order at our discretion. Payments must be made using the methods specified on our website.
          </Typography>

          <Typography variant="h6" component="h2" gutterBottom>
            5. Delivery
          </Typography>
          <Typography variant="body1" paragraph>
            We will make every effort to deliver your products within the timeframes specified. However, we are not responsible for delays caused by third parties or circumstances beyond our control.
          </Typography>

          <Typography variant="h6" component="h2" gutterBottom>
            6. Returns and Refunds
          </Typography>
          <Typography variant="body1" paragraph>
            Please refer to our Returns Policy for information on how to return products and request refunds. All returns are subject to our review and approval.
          </Typography>

          <Typography variant="h6" component="h2" gutterBottom>
            7. Intellectual Property
          </Typography>
          <Typography variant="body1" paragraph>
            All content on our website, including text, graphics, logos, and images, is the property of EV Trade Hub or its licensors and is protected by intellectual property laws.
          </Typography>

          <Typography variant="h6" component="h2" gutterBottom>
            8. Limitation of Liability
          </Typography>
          <Typography variant="body1" paragraph>
            To the fullest extent permitted by law, EV Trade Hub will not be liable for any indirect, incidental, special, or consequential damages arising from your use of our website or products.
          </Typography>

          <Typography variant="h6" component="h2" gutterBottom>
            9. Changes to Terms
          </Typography>
          <Typography variant="body1" paragraph>
            We may update these Terms and Conditions from time to time. Any changes will be posted on this page with an updated effective date. Your continued use of our website constitutes acceptance of the updated terms.
          </Typography>

          <Typography variant="h6" component="h2" gutterBottom>
            10. Contact Us
          </Typography>
          <Typography variant="body1" paragraph>
            If you have any questions or concerns about these Terms and Conditions, please contact us at:
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>EV Trade Hub</strong><br />
            <b>18 Gulab bagh infront of Metro Sale Dewas Naka Indore <br />
            Email: info@evtradehub.in<br />
            Phone:+91 9111777280
            </b>
          </Typography>
        </CardContent>
      </Card>
    </Container>
  );
};

export default TermsConditions;
