import React, { useEffect, useRef } from 'react';
import { Box, Grid, Typography, Card, CardContent } from '@mui/material';
import "../App.css"
import { useMediaQuery } from '@mui/material';

const Herosection = () => {
  const scrollRef = useRef(null);
  const isMobile = useMediaQuery('(max-width:600px)');
  useEffect(() => {
    const scrollContainer = scrollRef.current;
    const scrollStep = 1; // Amount to scroll each step
    const delay = 2000; // Delay between scrolls in milliseconds

    const startScrolling = () => {
      setInterval(() => {
        if (scrollContainer.scrollLeft + scrollContainer.clientWidth >= scrollContainer.scrollWidth) {
          scrollContainer.scrollLeft = 0; // Reset to start when reaching the end
        } else {
          scrollContainer.scrollLeft += scrollStep; // Scroll by step
        }
      }, delay);
    };

    startScrolling();
  }, []);

  return (
    <Box sx={{ flexGrow: 1, backgroundImage: 'linear-gradient(to right, #D7F0FF, white, white)' }}>
      <Grid container spacing={2} alignItems="center">
        {/* Logo and Main Text */}
        <Grid item xs={12} md={6} lg={4}>
          <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2, padding: 2 }}>
            MANUFACTURING HAPPINESS
          </Typography>
          <Typography variant="body1" sx={{ mb: 4, padding: 2 }}>
            With Innovation at its core, MotoCorp continues to provide mobility to the aspirations of millions around the world.
          </Typography>
        </Grid>

        {/* Stats */}
        <Grid item xs={12} md={6} lg={8}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={6} md={6}>
              <Card sx={{ minHeight: 150, display: 'flex', justifyContent: 'center', alignItems: 'center', boxShadow: 3 }}>
                <CardContent>
                  <Typography variant="h3" color="primary" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                    #1
                  </Typography>
                  <Typography variant="body2" sx={{ textAlign: 'center' }}>
                    Two-Wheeler Manufacturer
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6} md={6}>
              <Card sx={{ minHeight: 150, display: 'flex', justifyContent: 'center', alignItems: 'center', boxShadow: 3 }}>
                <CardContent>
                  <Typography variant="h3" color="primary" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                    10+
                  </Typography>
                  <Typography variant="body2" sx={{ textAlign: 'center' }}>
                  Variant  
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6} md={6}>
              <Card sx={{ minHeight: 150, display: 'flex', justifyContent: 'center', alignItems: 'center', boxShadow: 3 }}>
                <CardContent>
                  <Typography variant="h3" color="primary" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                    47+
                  </Typography>
                  <Typography variant="body2" sx={{ textAlign: 'center' }}>
                    Countries
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6} md={6}>
              <Card sx={{ minHeight: 150, display: 'flex', justifyContent: 'center', alignItems: 'center', boxShadow: 3 }}>
                <CardContent>
                  <Typography variant="h3" color="primary" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                    9000+
                  </Typography>
                  <Typography variant="body2" sx={{ textAlign: 'center' }}>
                    Customer Touch Points Worldwide
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        {/* Image Section */}
        <Grid item xs={12} sx={{ mt: 4, ml:1}}>
      <Box
        ref={scrollRef}
        sx={{
          display: 'flex',
          justifyContent: isMobile ? 'space-between' : 'flex-start', // Space between cards for mobile, flex-start for larger screens
          overflowX: 'scroll', // Enable horizontal scroll
          width: '100%',
          scrollBehavior: 'smooth', // Smooth scrolling effect
          '&::-webkit-scrollbar': { display: 'none' }, // Hide the scrollbar
        }}
      >
        <Box
          sx={{
            width: isMobile ? '50%' : '24%', // Show two cards per row on mobile
            marginRight: '10px',
            flexShrink: 0, // Prevent shrinking to ensure all items are visible in their size
          }}
        >
          <img src="../Product_IMG/5.png" alt="Bike 5" style={{ width: '100%' }} />
        </Box>

        <Box
          sx={{
            width: isMobile ? '50%' : '24%',
            marginRight: '10px',
            flexShrink: 0,
          }}
        >
          <img src="../Product_IMG/6.png" alt="Bike 6" style={{ width: '100%' }} />
        </Box>

        {!isMobile && ( // Hide these items on mobile view
          <>
            <Box
              sx={{
                width: '24%',
                marginRight: '10px',
                flexShrink: 0,
              }}
            >
              <img src="../Product_IMG/8.png" alt="Bike 3" style={{ width: '100%' }} />
            </Box>

            <Box
              sx={{
                width: '24%',
                marginRight: '10px',
                flexShrink: 0,
              }}
            >
              <img src="../Product_IMG/4.png" alt="Bike 4" style={{ width: '100%' }} />
            </Box>
          </>
        )}
      </Box>
    </Grid>

      </Grid>
    </Box>
  );
};

export default Herosection;
