import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardMedia, Typography, Button, Box, Grid, CircularProgress } from '@mui/material';
import "../App.css";

const New_product = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetch('https://twowheelerb2bapis.esavarirental.in/public/api/getProducts')
      .then(response => response.json())
      .then(data => {
        console.log('API Response:', data); // Debugging: check the structure of the response
        setProducts(data.data); // Update to set the correct array from the API response
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching products:', error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  // Check if products is an array before mapping
  if (!Array.isArray(products)) {
    return <Typography variant="h6" color="error">Failed to load products.</Typography>;
  }

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={2}>
        {products.map((product) => (
          <Grid item key={product._id} xs={12} sm={6} md={4} lg={3}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
                margin: { xs: '10px 0', sm: '10px' }, // Full margin on mobile, normal on larger screens
                boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
                backgroundColor: '#FFF',
                transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)',
                },
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#f5f5f5',
                  overflow: 'hidden',
                  '&:hover img': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                <CardMedia
                  component="img"
                  image={product.skus[0].image[0].source ? product.skus[0].image[0].source : ''}
                  alt={product.skus[0].name[0]}
                  sx={{
                    objectFit: 'contain',
                    height: '100%',
                    width: '100%',
                    transition: 'transform 0.3s ease-in-out',
                  }}
                />
              </Box>
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography gutterBottom variant="h5" component="div">
                  {product.name}
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  {product.description ? product.description.substring(0, 40)  : 'No description available.'}  
                  <span onClick={() => navigate(`/productnew/${product.id}`)} style={{color:"#2196f3", cursor:"pointer"}}>...more</span> 
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mt: 2 }}>
                  <Typography variant="h6" color="primary">
                   
                    <Typography variant="body2" color="text.secondary" sx={{ textDecoration: "line-through" }}>
                     
                    </Typography>
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Rating: {product.rating} ⭐
                  </Typography>
                </Box>
              </CardContent>
              <Box sx={{ p: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    width: '100%', // Ensure button takes full width on mobile
                    padding: '10px 0', // Padding for larger button
                    fontSize: { xs: '14px', sm: '16px' }, // Adjust font size for smaller screens
                  }}
                  onClick={() => navigate(`/productnew/${product.id}`)}
                >
                  Enquire Now
                </Button>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default New_product;
