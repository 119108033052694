import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Privacy from './Pages/Privacy';
import Term from './Pages/Term';
import { Link as RouterLink } from 'react-router-dom';
import Product from './component/Product';
import Home from './Pages/Home';
import Navbar from './component/Navbar';
import Footer from './component/Footer';
import "./App.css";
import ProductDetails from './component/Product_info';
import New_product from './component/New_product';
import ScrollToTop from './component/ScrollToTop';

const App = () => {
  const phoneNumber = '919111777280'; // Replace with your phone number
  const message = 'Hello! I am interested in your services. Could you please provide more information? '; // Replace with your default message

  const handleClick = () => {
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };

  return (
    <>
      <div>
       <ScrollToTop/>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/about' element={<About/>} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/term' element={<Term />} />
          <Route path='/productnew' element={<New_product/>} />
          <Route path='/productnew/:id' element={<ProductDetails />} />
        </Routes>
        <Footer/>
        <div onClick={handleClick} className='chatbot'>
          <img src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" alt="WhatsApp" style={{ width: '30%' }} />
          <span className='chat-text' style={{ background: "yellow", padding: "2%", borderRadius: "4px" }}>Chat with us</span>
        </div>
      </div>
    </>
  );
}

export default App;
