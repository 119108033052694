import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Grid, CircularProgress } from '@mui/material';
import axios from 'axios';
import "../App.css";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    message: '',
  });
  const [loading, setLoading] = useState(false);  // State for handling loader
  const [submitted, setSubmitted] = useState(false);  // State for disabling submit after form submission
  const [phoneError, setPhoneError] = useState('');  // State for handling phone number validation
  const [responseMessage, setResponseMessage] = useState('');  // State for response message

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Check if the input is for phone number and validate it
    if (name === 'phone') {
      // Allow only numbers and limit to 10 digits
      if (/^\d*$/.test(value) && value.length <= 10) {
        setFormData({
          ...formData,
          [name]: value,
        });
        // Set phoneError based on the length
        setPhoneError(value.length === 10 ? '' : 'Mobile number must be exactly 10 digits');
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);  // Start loading when submitting the form

    const { firstName, lastName, email, phone, message } = formData;

    // Check if phone number is valid
    if (phone.length !== 10) {
      setPhoneError('Mobile number must be exactly 10 digits');
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post('https://esavarirental.in/twowheelerb2bapis/public/api/submitQuery', {
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone: phone,
        query: message,
      });

      // Debugging: Log response to inspect its structure
      console.log('Form submitted successfully', response.data);

      // Assuming response.data contains a message or status
      setResponseMessage(response.data.message || 'Your query has been submitted successfully!');
      alert('Thank you for contacting us! We will get back to you soon.');
      setLoading(false);  // Stop loading
      setSubmitted(true);  // Disable the form once submission is successful
      
    } catch (error) {
      console.error('There was an error submitting the form!', error);
      setResponseMessage('Failed to submit the form. Please try again later.');
      alert('Failed to submit the form. Please try again later.');
      setLoading(false);  // Stop loading in case of error
    }
  };

  return (
    <div className='contact-main-div'>
      <img src="../Product_IMG/pro_img.jpg" width={"40%"} alt="Product" />
      <Box sx={{ padding: '20px', maxWidth: '500px', margin: '0 auto' }}>
        <Typography variant="h4" align="center" sx={{ mb: 4 }}>
          Get in Touch with Us
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="First Name"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
                disabled={submitted} // Disable input after form submission
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Last Name"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                disabled={submitted} // Disable input after form submission
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                required
                disabled={submitted} // Disable input after form submission
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Mobile Number"
                name="phone"
                type="number"
                value={formData.phone}
                onChange={handleChange}
                required
                disabled={submitted} // Disable input after form submission
                error={!!phoneError}  // Show error when phone number is invalid
                helperText={phoneError}  // Display error message if validation fails
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                disabled={submitted} // Disable input after form submission
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Message"
                name="message"
                multiline
                rows={4}
                value={formData.message}
                onChange={handleChange}
                disabled={submitted} // Disable input after form submission
              />
            </Grid>
          </Grid>
          <Box sx={{ mt: 4, textAlign: 'center' }}>
            <Button 
              type="submit" 
              variant="contained" 
              color="primary" 
              sx={{ padding: '10px 30px' }} 
              disabled={loading || submitted} // Disable button when loading or submitted
            >
              {loading ? <CircularProgress size={24} /> : 'Submit'} {/* Show loader or text */}
            </Button>
          </Box>
        </form>
        {responseMessage && (
          <Typography variant="body1" align="center" sx={{ mt: 2 }}>
            {responseMessage}
          </Typography>
        )}
      </Box>
    </div>
  );
};

export default ContactForm;
